import React, { useRef } from "react";
import { Element, Link } from "react-scroll";
import { Title } from "./Title";
import { Rail, Ref, Segment, Sticky } from "semantic-ui-react";
import { MenuTop } from "./MenuTop";

export const Portfolio = () => {
  let contextRef = useRef(null);
  return (
    <>
      <Link to="test1" spy={true} smooth={true} duration={500}>
        <Title />
      </Link>
      <Ref innerRef={contextRef}>
        <Segment className="menu-segment">
          <div className="menu-rail">
            <Rail position={"right"}>
              <Sticky offset={50} context={contextRef} pushing>
                <MenuTop />
              </Sticky>
            </Rail>
          </div>
          <Element name="test1" className="element" id="gamepad">
            test 1
          </Element>
          <Element name="test2" className="element" id="video camera">
            test 2
          </Element>
          <Element name="test3" className="element" id="video play">
            test 3
          </Element>
        </Segment>
      </Ref>
    </>
  );
};

Portfolio.propTypes = {};

Portfolio.defaultProps = {};
