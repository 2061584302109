import React, { useState } from "react";
import "./index.css";
import { Link } from "react-scroll";
import { Icon, Menu } from "semantic-ui-react";

export const MenuTop = () => {
  const [activeItem, setActiveItem] = useState("");
  const handleItemClick = (e, { name }) => setActiveItem(name);
  const handleSetActive = (e, { id }) => setActiveItem(id);
  const handleSetInactive = () => setActiveItem("");
  return (
    <Menu compact icon="labeled">
      <Link
        className="menu-link"
        to="test1"
        spy={true}
        smooth={true}
        duration={500}
        onSetActive={handleSetActive}
        onSetInactive={handleSetInactive}
      >
        <Menu.Item
          as="span"
          name="gamepad"
          active={activeItem === "gamepad"}
          onClick={handleItemClick}
        >
          <Icon name="gamepad" />
          Games
        </Menu.Item>
      </Link>

      <Link
        className="menu-link"
        to="test2"
        spy={true}
        smooth={true}
        duration={500}
        onSetActive={handleSetActive}
      >
        <Menu.Item
          as="span"
          name="video camera"
          active={activeItem === "video camera"}
          onClick={handleItemClick}
        >
          <Icon name="video camera" />
          Channels
        </Menu.Item>
      </Link>

      <Link
        className="menu-link"
        to="test3"
        spy={true}
        smooth={true}
        duration={500}
        onSetActive={handleSetActive}
      >
        <Menu.Item
          as="span"
          name="video play"
          active={activeItem === "video play"}
          onClick={handleItemClick}
        >
          <Icon name="video play" />
          Videos
        </Menu.Item>
      </Link>
    </Menu>
  );
};
