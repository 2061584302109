import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import { Portfolio } from "./components/Portfolio";

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route path="/" component={Portfolio} />
      </Switch>
    </div>
  );
};
export default App;
