import React from "react";
import { Random } from "react-animated-text";
import "./index.css";

export const Title = () => (
  <div className="title-container">
    <header className="App-header">
      <img src="/logo.svg" className="App-logo" alt="logo" />
      <div className="title">
        <Random
          text="ADI TEJA"
          iterations={1}
          effect="verticalFadeIn"
          effectChange={6}
          effectDirection="up"
        />
      </div>
      <h1>Welcome to Adi's website.</h1>
      <p>aditeja.com under construction.</p>
      <button className="App-button">Click Anywhere</button>
    </header>
  </div>
);
